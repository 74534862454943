<template>
  <v-navigation-drawer
    v-model="drawer.toggle"
    app
    clipped
    temporary
    class="drawer"
    :class="{ 'toggle': drawer.toggle }"
  >
    <template v-slot:prepend>
      <v-toolbar 
        flat 
        class="pa-3 mb-2"
      >
        <icon-base
          width="151"
          height="163"
          icon-name="hive"
          class="hive primary--text"
        >
          <brand-symbol />
        </icon-base>
        <v-toolbar-title>
          <icon-base
            width="126"
            height="26"
            icon-name="Mobees"
            class="logo primary--text mr-8"
          >
            <brand-logo />
          </icon-base>
        </v-toolbar-title>
      </v-toolbar>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, i) in drawer.items"
        :key="i"
        :to="item.to"
        color="primary"
        class="pa-2 px-7"
      >
        <v-list-item-icon class="mr-4">
          <v-icon v-text="icons[item.icon]" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="item.text" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        color="primary"
        class="pa-2 px-7"
        @click="getout()"
      >
        <v-list-item-icon class="mr-4">
          <v-icon v-text="icons.mdiClose" />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="'Sair'" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-alert
      dense
      color="grey lighten-4"
      class="app-version ma-4 d-flex"
    >
      <span class="caption">
        {{ 'v ' + appVersion }}
      </span>
      <v-btn 
        icon
        :loading="updateBtn.loading"
        class="btn-update"
        @click="checkUpdates"
      >
        <v-icon color="grey lighten-1">{{ icons.mdiRefresh }}</v-icon>
      </v-btn>
    </v-alert>
  </v-navigation-drawer>
</template>

<style>

  .drawer {
    /* z-index: 3; */
  }

  .drawer .hive {
    height: 56px;
    margin-left: -14px;
  }

  .drawer .logo {
    height: 16px;
  }

  .drawer .app-version {
    position: absolute;
    bottom: 0;
    opacity: .5;
  }
  .drawer .app-version .btn-update {
    position: absolute;
    top: 2px;
    right: -40px;
  }
  
</style>

<script>

  // Utilities
  import services from '@/services'
  import {
    sync,
  } from 'vuex-pathify'

  // Icons
  import {
    mdiMapMarkerMultipleOutline,
    mdiAccountOutline,
    mdiImageOutline,
    mdiClose,
    mdiViewDashboard,
    mdiRefresh
  } from '@mdi/js'

  export default {
    name: 'Drawer',

    components: {
      IconBase: () => import('@/components/IconBase'),
      BrandSymbol: () => import('@/components/icons/BrandSymbol'),
      BrandLogo: () => import('@/components/icons/BrandLogo'),
    },

    data: () => ({
      icons: {
        mdiMapMarkerMultipleOutline,
        mdiAccountOutline,
        mdiImageOutline,
        mdiClose,
        mdiViewDashboard,
        mdiRefresh
      },
      appVersion: APP_VERSION,
      updateBtn: {
        loading: false,
        tooltip: 'Update Check'
      },
      timeout: null
    }),

    computed: {
      drawer: sync('app/views@drawer'),
      user: sync('user/data'),
      toast: sync('app/toast'),
    },

    methods: {
      ...services,

      checkUpdates () {
        // check for new app version & cache store consistency
        this.updateBtn.loading = true;
        this.drawer.toggle = false; 
        this.$emit('reset');
      }
    },

    beforeDestroy () {
      this.drawer.toggle = false;
    },

  }
</script>
